// Signup.js
import React, { useState } from 'react';
import { auth, db, doc, setDoc, createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from './firebase'; // Assuming these imports are accurate based on your setup

function Signup({ setUser }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSignup = async (e) => {
			e.preventDefault();
			try {
				// Check if the email already exists
				const existingMethods = await fetchSignInMethodsForEmail(auth, email);
				if (existingMethods.length > 0) {
					alert('This email is already in use. Try signing in.');
					return;
				}

				const response = await createUserWithEmailAndPassword(auth, email, password);
				const newUser = response.user;

				// Create a document for the user in the Firestore 'users' collection
				const userDocRef = doc(db, "users", newUser.uid);
				await setDoc(userDocRef, {
					homeid: null, // You can set up a default value or leave it as null.
					email: newUser.email,
					uid: newUser.uid
				});

				setUser(newUser);
			} catch (error) {
				alert(error.message);
			}
		};

    return (
        <div>
            <h1>Sign Up</h1>
            <form onSubmit={handleSignup}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Sign Up</button>
            </form>
        </div>
    );
}

export default Signup;
