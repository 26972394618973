// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* semi-transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* to ensure it's on top */
}

.modal-content {
    width: 400px;
    /* you can adjust this */
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,sCAAsC;IACtC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,wBAAwB;IACxB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yCAAyC;IACzC,kBAAkB;AACtB","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7);\n    /* semi-transparent black background */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n    /* to ensure it's on top */\n}\n\n.modal-content {\n    width: 400px;\n    /* you can adjust this */\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 10px;\n    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
