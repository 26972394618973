import React, { useState } from 'react';
import {
  auth,
  db,
  collection,
  getDocs,
  setDoc,
  where,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  signOut,
  query,
  addDoc
} from './firebase';
import './Dashboard.css';

const neighborCodesCollection = collection(db, 'neighborCodes');
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function Dashboard({ user, homes, neighbors, togglePorchlight, setUser }) {
  const [showAddNeighbor, setShowAddNeighbor] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [enteredCode, setEnteredCode] = useState(""); // Newly added state for entered code


  const generateCode = async () => {
    try {
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      const existingCodeQuery = query(neighborCodesCollection, where('homeid', '==', user.homeid), where('createdAt', '>', oneWeekAgo));
      const existingCodeSnapshot = await getDocs(existingCodeQuery);

      if (!existingCodeSnapshot.empty) {
        const existingCodeDoc = existingCodeSnapshot.docs[0];
        setGeneratedCode(existingCodeDoc.data().code);
      } else {
        // Generate a new unique code
        let code;
        let isUnique = false;

        while (!isUnique) {
          code = '';
          for (let i = 0; i < 6; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
          }

          // Check if code is unique
          const codeQuery = query(neighborCodesCollection, where('code', '==', code));
          const codeSnapshot = await getDocs(codeQuery);

          if (codeSnapshot.empty) {
            isUnique = true;
          }
        }

        // Save the generated code to the database
        await addDoc(neighborCodesCollection, {
          code: code,
          homeid: user.homeid,
          createdAt: new Date()
        });

        setGeneratedCode(code);
      }

      setShowModal(true);
    } catch (error) {
      console.error("Error generating code:", error);
    }
  };

  const handleEnterCode = async (code) => {
    try {
      const codeQuery = query(neighborCodesCollection, where('code', '==', code));
      const codeSnapshot = await getDocs(codeQuery);

      if (!codeSnapshot.empty) {
        if (!window.confirm("Are you sure you want to submit this code?")) {
          return; // exit function if user clicked Cancel
        }
        const codeDoc = codeSnapshot.docs[0];
        const generatingHomeRef = codeDoc.data().homeid;

        // Add this user's home as a neighbor to the generating home
        const generatingHomeDoc = await getDoc(generatingHomeRef);
        const neighborsOfGeneratingHome = generatingHomeDoc.data().neighbors;
        neighborsOfGeneratingHome.push(user.homeid);

        await updateDoc(generatingHomeRef, {
          neighbors: neighborsOfGeneratingHome
        });

        // Add the generating home as a neighbor to this user's home
        const currentHomeDoc = await getDoc(user.homeid);
        const neighborsOfCurrentHome = currentHomeDoc.data().neighbors;
        neighborsOfCurrentHome.push(generatingHomeRef);

        await updateDoc(user.homeid, {
          neighbors: neighborsOfCurrentHome
        });
      } else {
        console.log("Invalid code entered.");
      }
    } catch (error) {
      console.error("Error handling entered code:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Clear user state after signing out
      setUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div>
      <h1>Hello, {user.email}</h1>
      <h2>My Home:</h2>
      <table>
        <thead>
          <tr>
            <th>Alias</th>
            <th>Neighbors</th>
            <th>Porchlight On</th>
            <th>Members</th>
            <th>Neighbor's Porchlights</th>
          </tr>
        </thead>
        <tbody>
          {homes.map((home) => (
            <tr key={home.id}>
              <td>{home.alias}</td>
              <td>{neighbors.map(neighbor => neighbor.alias).join(', ')}</td>
              <td>
                {home.porchlighton ? 'On' : 'Off'}
                <button onClick={() => togglePorchlight(home.id, home.porchlighton)}>
                  Toggle
                </button>
              </td>
              <td>{home.members.join(', ')}</td>
              <td>
                {neighbors.map(neighbor => (
                  `${neighbor.alias}: ${neighbor.porchlighton ? 'On' : 'Off'}`
                )).join(', ')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        <a href="#" onClick={() => setShowAddNeighbor(true)}>Add neighbor</a>
      </p>
      {showAddNeighbor && (
        <div>
          <input type="text" placeholder="Enter a code" onChange={(e) => setEnteredCode(e.target.value)} />
          <button onClick={() => handleEnterCode(enteredCode)}>OK</button>
          <button onClick={generateCode}>Generate code</button>
        </div>
      )}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* ... [unchanged code] */}
          </div>
        </div>
      )}
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Dashboard;
